<template>
  <div>
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary font-weight-bolder text-primary mb-0">
          บริการฝากเงิน
        </h3>
      </div>
      <div>

        <div>
          <div
            class="card-out"
            @click="$router.push({ name: 'bank' })"
          >
            <div class="card-bank2">
              <div class="card-body">

                <div class="text-center">
                  <img
                    src="@/assets/images/newIcon/bank.png"
                    alt="card"
                    class="img-fluid"
                  >
                  <h5 class="mb-0 mt-75 text-white">
                    บัญชีธนาคาร
                  </h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
// import ThisHeader from '../components/ThisHeader.vue'
import ThisFooter from '../components/ThisFooter.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'ฝากเงิน',
  },
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

  <style scoped>
  </style>
